import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * A service that stores any interesting routing state information
 */
@Injectable({
  providedIn: 'root'
})
export class RoutingStateService implements OnDestroy {
  private serviceDestroyed: Subject<boolean> = new Subject();
  /** The last attempted started navigation. */
  lastNavigationStart: NavigationStart;

  constructor(private readonly router: Router) {
    this.router.events.pipe(takeUntil(this.serviceDestroyed)).subscribe(event => {
      if (event instanceof NavigationStart) {
        this.lastNavigationStart = event;
      }
    });
  }

  ngOnDestroy(): void {
    this.serviceDestroyed.next(true);
    this.serviceDestroyed.unsubscribe();
  }
}
