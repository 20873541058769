import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import 'app/extensions/string.extensions';
import 'moment/locale/nl';
import moment from 'moment';
import { environment } from './environments/environment';

moment.locale('nl');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  preserveWhitespaces: false
});
