import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CmsFeature } from 'app/shared/models/features/cms.feature';

@Injectable({
  providedIn: 'root'
})
export class CmsGuard implements CanActivate {

  constructor(readonly cmsFeature: CmsFeature) {}

  canActivate(): boolean {
    return this.cmsFeature.isEnabled;
  }
}
