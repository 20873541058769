import { Component, OnInit } from '@angular/core';

import { BreadcrumbService } from 'app/core/breadcrumb.service';
import { Breadcrumb } from './breadcrumb';

declare let window: any;

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[];

  constructor(private breadcrumbService: BreadcrumbService) {}

  isMagentaUrl(url: string): boolean {
    return url.indexOf((window as any).magentaBaseUrl) === 0;
  }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe((crumbs: Breadcrumb[]) => {
      this.onBreadcrumbChange(crumbs);
    });
  }

  private onBreadcrumbChange(crumbs: Breadcrumb[]) {
    this.breadcrumbs = crumbs;
  }
}
