<div class="breadcrumbs mb-3">
  <div class="container">
    <div class="row">
      <div class="column-12">
        <ol class="breadcrumb-list">
          <li>
            <!-- To prevent problems, the breadcrumb does a full refresh on logo click -->
            <a href="/">Odido</a>
          </li>
          <ng-container *ngIf="breadcrumbs">
            <ng-template ngFor let-route [ngForOf]="breadcrumbs" let-isLast="last">
              <li *ngIf="!isLast" class="breadcrumb-item">
                <a href="" [routerLink]="[route.url]" *ngIf="!isMagentaUrl(route.url); else externalUrl">{{ route.displayName }}</a>
                <ng-template #externalUrl>
                  <a [href]="route.url">{{ route.displayName }}</a>
                </ng-template>
              </li>
              <li *ngIf="isLast" class="breadcrumb-item active" aria-current="page">
                <span>{{ route.displayName }}</span>
              </li>
            </ng-template>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>