<app-header *ngIf="!navigationHidden"></app-header>
<app-loading-indicator class="w-100"></app-loading-indicator>

<main role="main">
  <!--initial loading indicator while guards/resolvers are loading-->
  <ng-container *ngIf="loadingIndicatorService.isInitiallyLoading || !designSystemsLoaded">
    <app-loading-indicator></app-loading-indicator>
    <section class="section section-square-pattern"></section>
  </ng-container>

  <router-outlet *ngIf="!loadingIndicatorService.isInitiallyLoading && designSystemsLoaded"></router-outlet>
</main>

<iframe
  *ngIf="magentaSSOKeepAliveEndpoint"
  [src]="magentaSSOKeepAliveEndpoint | safe: 'url'"
  height="0"
  width="0"
  style="display: none; visibility: hidden"
></iframe>

<app-footer *ngIf="!navigationHidden"></app-footer>
