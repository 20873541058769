import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AgentLoginComponent } from 'app/core/agentlogin/agentlogin.component';
import { LoadErrorComponent } from 'app/core/errors/load-error/load-error.component';
import { NotFoundComponent } from 'app/core/errors/notfound/notfound.component';
import { HomeComponent } from 'app/core/home/home.component';
import { AuthCallbackComponent } from './auth/auth-callback.component';
import { AuthLogoutComponent } from './auth/auth-logout.component';
import { AuthGuard } from './auth/auth.guard';
import { MaintenanceComponent } from './core/errors/maintenance/maintenance.component';
import { CmsGuard } from './modules/cms/cms.guard';
import { HouseholdRouteConstants } from './shared/constants/household-route-constants';
import { DemoGuard } from './shared/demo.guard';
import { MigratedOrConvergedUserGuard } from './shared/migrated-or-converged-user.guard';
import { UserGuard } from './shared/user.guard';

// TODO: Check all usages of shared CommonModule / SharedModule
const appRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'error', component: LoadErrorComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'logout', component: AuthLogoutComponent },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'agentlogin/:id', component: AgentLoginComponent },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { handleHttpExceptions: false },
        canActivate: [UserGuard]
      },
      {
        path: 'download-inzageverzoek',
        loadChildren: () => import('./modules/gdpr/gdpr.module').then((m) => m.GdprModule),
        data: { title: 'Inzageverzoek afgehandeld' },
        canActivate: [UserGuard]
      },
      {
        path: 'rekeningen',
        loadChildren: () => import('./modules/invoices/invoices.module').then((m) => m.InvoicesModule),
        data: { title: 'Rekeningen' },
        canActivate: [UserGuard]
      },
      {
        path: 'bestellingen',
        loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule),
        data: { title: 'Bestellingen' }
      },
      {
        path: 'abonnementen',
        loadChildren: () => import('./modules/shop/shop.module').then((m) => m.ShopModule),
        data: { title: 'Abonnementen' },
        canActivate: [UserGuard]
      },
      {
        path: 'gebruikskosten',
        loadChildren: () => import('./modules/usage/usage.module').then((m) => m.UsageModule),
        data: { title: 'Gebruikskosten' },
        canActivate: [UserGuard]
      },
      {
        path: 'instellingen',
        loadChildren: () => import('./modules/configuration/configuration.module').then((m) => m.ConfigurationModule),
        data: { title: 'Instellingen', breadcrumbTarget: '/instellingen/tv' },
        canActivate: [UserGuard]
      },
      {
        path: 'mijn-gegevens',
        loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule),
        data: { title: 'Mijn gegevens' }
      },
      {
        path: 'storingen',
        loadChildren: () => import('./modules/outage/outage.module').then((m) => m.OutageModule),
        data: { title: 'Actuele storingen' },
        canActivate: [MigratedOrConvergedUserGuard]
      },
      {
        path: 'overstappen',
        loadChildren: () => import('./modules/transfer/transfer.module').then((m) => m.TransferModule),
        data: { title: 'Overstappen' }
      },
      {
        path: HouseholdRouteConstants.householdRoot,
        loadChildren: () => import('./modules/household/household.module').then((m) => m.HouseholdModule),
        canActivate: [UserGuard]
      }
    ]
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./modules/opt-out/opt-out.module').then((m) => m.OptOutModule)
  },
  {
    path: 'retour-label',
    loadChildren: () => import('./modules/orders/shipping-label/shipping-label.module').then((m) => m.ShippingLabelModule)
  },
  {
    path: 'overstappen-afronden',
    loadChildren: () =>
      import('./modules/complete-transfer-details/complete-transfer-details.module').then((m) => m.CompleteTransferDetailsModule)
  },
  {
    path: 'nieuwe-gebruikersnaam',
    loadChildren: () =>
      import('./modules/set-primary-email-success/set-primary-email-success.module').then((m) => m.SetPrimaryEmailSuccessModule)
  },
  {
    path: 'cms',
    loadChildren: () => import('./modules/cms/cms.module').then((m) => m.CmsModule),
    data: { title: 'CMS' },
    canActivate: [ CmsGuard ]
  },
  {
    path: 'demo',
    loadChildren: () => import('./modules/demo/demo.module').then((m) => m.DemoModule),
    data: { title: 'Demo' },
    canActivate: [DemoGuard]
  },
  {
    path: 'TaskBOFeedback',
    loadChildren: () => import('./modules/feedback/feedback.module').then((m) => m.FeedbackModule),
    data: { title: 'Je melding is opgelost.' }
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: NoPreloading,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
