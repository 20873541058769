<div class="main-nav-dashboard">
  <div class="container">
      <div class="row">
          <div class="column-12">
              <ul class="list-inline justify-content-between">
                <li><a class="main-nav-dashboard-link" href="https://www.odido.nl">Terug naar odido.nl</a></li>
                <li><a class="main-nav-dashboard-link" href="https://www.odido.nl/service">Naar Service</a></li>
              </ul>
          </div>
      </div>
  </div>
</div>

<header class="glow-static py-6">
  <div class="container">
    <div class="row">
      <ng-container *ngTemplateOutlet="userheader"></ng-container>
      <div class="column-auto flex align-items-center" *ngIf="loggedIn">
        <a class="navbar-link navbar-user hidden-tablet-down" *ngIf="account?.isAgent && agentCustomerCode" (click)="logout()" title="{{ 'logout' | content}}">
          <span class="icon-service-employee mr-1"></span>
          <div>
            <span class="ml-1" data-hj-masked>{{ username }}</span>
            <span class="ml-1" data-hj-masked>{{ agentCustomerCode }}</span>
          </div>
        </a>
        <div class="d-block">
          <div [ngClass]="account?.magentaSubscriptions?.length > 0 ? 'navbar-user' : 'no-pointer-events'" class="hidden-mobile-down">
            <ng-container *ngTemplateOutlet="thuisHeader"></ng-container>
          </div>
          <div *ngIf="account?.isAgent" class="hidden-mobile-down">
            <span
              *ngIf="!agentCustomerCode"
              class="text-weight-bold text-extra-small"
              [innerHTML]="'close-tab-to-logout-text' | content"
            ></span>
          </div>
          <div *ngIf="account?.isAgent == false" [ngClass]="account?.magentaSubscriptions?.length > 0 ? 'navbar-user' : 'no-pointer-events'" class="hidden-tablet-up" data-navbar-user>
            <ng-container *ngTemplateOutlet="thuisHeader"></ng-container>
          </div>
          <div *ngIf="account?.isAgent" class="hidden-tablet-up" data-navbar-user>
            <a *ngIf="agentCustomerCode" role="button" data-modal-toggle="#modal-user-menu" class="navbar-user">
              <span class="icon-service-employee"></span>
            </a>
            <span class="icon-service-employee" *ngIf="!agentCustomerCode"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="modal fade" id="modal-user-menu" tabindex="-1" role="dialog" aria-labelledby="modal-user-menu" aria-hidden="true" *ngIf="account?.magentaSubscriptions?.length > 0">
  <div class="modal-dialog modal-dialog-drawer modal-dialog-drawer-top">
    <div class="modal-content h-auto">
      <div class="modal-header justify-content-center">
        <h3 class="modal-title">Mijn abonnementen</h3>
        <button type="button" class="modal-close-button" data-modal-dismiss="" aria-label="Close"></button>
      </div>
      <div class="modal-body border-top py-5">
        <div class="container">
          <div class="row">
            <div class="column-12 column-desktop-large-8 mx-desktop-auto">
              <div class="row no-gutters w-100">
                <app-subscription-switcher
                  *ngIf="loggedIn"
                  class="w-100"
                  [account]="account"
                >
                </app-subscription-switcher>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer border-top pb-6">
        <div class="container w-100">
            <div class="row">
                <div class="column-12 flex align-items-center">
                    <div class="flex align-items-center">
                      <div *ngIf="customerData?.customerInitials?.length === 2" class="avatar avatar-black mr-2">{{ customerData?.customerInitials }}</div>
                      <span *ngIf="customerData && !account.magentaVkfe" class="text-transform-reset hidden-mobile-down customer-name">{{ customerData.customerNaturalName }}</span>
                      <a *ngIf="customerData && account.magentaVkfe" class="customer-data" [appTrackingNav]="'Mijn account'" [href]="magentaAccountUrl">Mijn account</a>
                    </div>
                    <div class="flex align-items-center ml-auto">
                        <a *ngIf="account?.isAgent == false" class="navbar-link" (click)="logout()">
                          <span class="icon-logout icon-size-s"></span>
                          <span class="ml-1 is-underlined">{{ 'logout' | content}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #userheader>
  <div class="column flex align-items-center">
    <div>
      <a *ngIf="account?.magentaVkfe" class="customer-data" [appTrackingNav]="'Mijn account'" [href]="magentaAccountUrl">
        <span class="icon-chevron-left icon-size-s mr-2"></span>
        <ng-container *ngTemplateOutlet="userheadercontent"></ng-container>
      </a>

      <div *ngIf="!account?.magentaVkfe">
        <ng-container *ngTemplateOutlet="userheadercontent"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #userheadercontent>
  <div class="avatar avatar-white mr-2" [ngClass]="{'hidden': canHideAvatar()}">{{ customerData?.customerInitials }}</div>
  <span *ngIf="customerData" class="text-transform-reset hidden-mobile-down customer-name">{{ account?.magentaVkfe ? 'Mijn account' : customerData.customerNaturalName }}</span>
</ng-template>

<ng-template #thuisHeader>
  <div class="flex align-items-center ml-auto" style="position: relative" role="button" data-modal-toggle="#modal-user-menu">
    <img class="mr-3" [src]="iconSrc" style="max-width: 24px">
    <strong class="no-link-tel mr-2 alias">{{ account?.magentaAlias ? account.magentaAlias : productName$ | async }}</strong>
    <span class="icon-chevron-down-solid" *ngIf="account?.magentaSubscriptions?.length > 0"></span>
    <button aria-label="Meerdere gelinkte abonnementen" class="button-link stretched-link" data-modal-toggle="#navbar-user-modal" type="button"></button>
  </div>
</ng-template>
