import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthCallbackComponent } from 'app/auth/auth-callback.component';
import { AuthLogoutComponent } from 'app/auth/auth-logout.component';
import { HouseHoldSubscribeAddressResolver } from 'app/core/resolvers/household-subscribe-address.resolver';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './breadcrumb.service';
import { ErrorStatusComponent } from './errors/error-status/error-status.component';
import { LoadErrorComponent } from './errors/load-error/load-error.component';
import { MaintenanceComponent } from './errors/maintenance/maintenance.component';
import { NotFoundComponent } from './errors/notfound/notfound.component';
import { FooterComponent } from './footer/footer.component';
import { SubscriptionSwitcherComponent } from './header/components/subscription-switcher.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { NavigationItemAccountComponent } from './navigation/nav-items/navigation-item-account.component';
import { NavigationItemLogoutComponent } from './navigation/nav-items/navigation-item-logout.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationContentService } from './navigation/services/navigation-content.service';
import { NavigationService } from './navigation.service';
import { AccountResolver } from './resolvers/account.resolver';
import { AvailableInternetProductsResolver } from './resolvers/available-internet-products.resolver';
import { AvailableWifiExtenderProductsResolver } from './resolvers/available-wifiextender-products.resolver';
import { CloudRecordingResolver } from './resolvers/cloud-recording.resolver';
import { SafeConvergedStatusResolver } from './resolvers/converged-status-safe.resolver';
import { ConvergedStatusResolver } from './resolvers/converged-status.resolver';
import { EntertainmentViaplayResolver } from './resolvers/entertainment-viaplay.resolver';
import { FeatureConfigurationResolver } from './resolvers/feature-configuration.resolver';
import { FSecureResolver } from './resolvers/fsecure.resolver';
import { HasGdprDataResolver } from './resolvers/has-gdpr-data.resolver';
import { OpenSupportRequestsResolver } from './resolvers/open-support-requests.resolver';
import { OutageResolver } from './resolvers/outage-resolver';
import { PhoneNumberResolver } from './resolvers/phone-number.resolver';
import { SubscriptionResolver } from './resolvers/subscription.resolver';
import { SubscriptionStatusResolver } from './resolvers/subscriptionstatus.resolver';
import { TvResolver } from './resolvers/tv.resolver';
import { VeiligOnlineProductOrderHistoryResolver } from './resolvers/veilig-online-product-order-history.resolver';
import { AccountService } from './services/account.service';
import { AddressLookupService } from './services/address-lookup.service';
import { ConvergedService } from './services/converged.service';
import { EntertainmentService } from './services/entertainment.service';
import { GdprService } from './services/gdpr.service';
import { HouseholdService } from './services/household.service';
import { InstallmentPlanService } from './services/installmentplan.service';
import { InvoicesService } from './services/invoices.service';
import { OptOutService } from './services/opt-out.service';
import { OrderService } from './services/order.service';
import { OutageService } from './services/outage.service';
import { PhonenumbersService } from './services/phonenumbers.service';
import { PostponePaymentService } from './services/postpone-payment.service';
import { ProductNameService } from './services/product-name.service';
import { ProductsService } from './services/products.service';
import { RemoteMechanicService } from './services/remote-mechanic.service';
import { SubscriptionService } from './services/subscription.service';
import { TelevisionService } from './services/television.service';
import { TransferService } from './services/transfer.service';
import { TvProductsService } from './services/tv-products.service';
import { TvService } from './services/tv.service';
import { UrlService } from './services/url.service';
import { UsageService } from './services/usage.service';
import { VoucherService } from './services/vouchers.service';

/**
 * The CoreModule takes on the role of the root AppModule , but is not the module which gets bootstrapped
 * by Angular at run-time.
 * The CoreModule should contain singleton services (which is usually the case), universal components
 * and other features where there’s only once instance per application.
 * To prevent re-importing the core module elsewhere, you should also add a guard for it in the core module’ constructor.
 */

@NgModule({
  declarations: [
    AuthCallbackComponent,
    AuthLogoutComponent,
    BreadcrumbComponent,
    ErrorStatusComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoadErrorComponent,
    SubscriptionSwitcherComponent,
    MaintenanceComponent,
    NavigationComponent,
    NavigationItemAccountComponent,
    NavigationItemLogoutComponent,
    NotFoundComponent
  ],
  imports: [CommonModule, SharedModule, AppRoutingModule],
  exports: [
    AppRoutingModule,
    AuthCallbackComponent,
    AuthLogoutComponent,
    BreadcrumbComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoadErrorComponent,
    NavigationComponent,
    NavigationItemAccountComponent,
    NavigationItemLogoutComponent,
    NotFoundComponent,
    SubscriptionSwitcherComponent
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AccountService,
        AddressLookupService,
        BreadcrumbService,
        ConvergedService,
        EntertainmentService,
        GdprService,
        HouseholdService,
        InstallmentPlanService,
        InvoicesService,
        NavigationContentService,
        NavigationService,
        OptOutService,
        OrderService,
        OutageService,
        PhonenumbersService,
        PostponePaymentService,
        ProductsService,
        ProductNameService,
        RemoteMechanicService,
        SubscriptionService,
        TelevisionService,
        TransferService,
        TvProductsService,
        TvService,
        UrlService,
        UsageService,
        VoucherService,
        AccountResolver,
        AvailableInternetProductsResolver,
        AvailableWifiExtenderProductsResolver,
        CloudRecordingResolver,
        ConvergedStatusResolver,
        EntertainmentViaplayResolver,
        FeatureConfigurationResolver,
        FSecureResolver,
        HasGdprDataResolver,
        HouseHoldSubscribeAddressResolver,
        OpenSupportRequestsResolver,
        OutageResolver,
        PhoneNumberResolver,
        SafeConvergedStatusResolver,
        SubscriptionResolver,
        SubscriptionStatusResolver,
        TvResolver,
        VeiligOnlineProductOrderHistoryResolver
      ]
    };
  }
}
