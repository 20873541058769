import { Injectable } from '@angular/core';
import { OAuthLogger } from 'angular-oauth2-oidc';
import { LoggingService } from 'app/core/logging.service';

@Injectable()
export class AuthLogging implements OAuthLogger {
  constructor(private loggingService: LoggingService) {}

  debug(message?: any, ...optionalParams: any[]): void {
    this.loggingService.debug(this, message, optionalParams);
  }

  error(message?: any, ...optionalParams: any[]): void {
    this.loggingService.warn(this, message, null, optionalParams);
  }

  info(message?: any, ...optionalParams: any[]): void {
    this.loggingService.info(this, message, optionalParams);
  }

  log(message?: any, ...optionalParams: any[]): void {
    this.loggingService.info(this, message, optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]): void {
    this.loggingService.warn(this, message, null, optionalParams);
  }
}
