import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProductNameService } from 'app/core/services/product-name.service';
import { ProductsService } from 'app/core/services/products.service';
import { UrlService } from 'app/core/services/url.service';
import { MagentaSubscriptionModel, SubscriptionIconType } from 'app/shared/magenta-subscription.model';
import { SubscriptionModel } from 'app/shared/open-api/data-contracts';
import { UserInformationModel } from 'app/shared/userinformation.models';
import { Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'app-subscription-switcher',
  templateUrl: './subscription-switcher.component.html',
  styleUrls: ['./subscription-switcher.component.scss']
})
export class SubscriptionSwitcherComponent implements OnInit, OnDestroy {
  private magentaSubscriptionUrl: string;
  private productName: string;
  private productsSubscription: Subscription;

  @Input() account: UserInformationModel | null;
  subscriptions: MagentaSubscriptionModel[];

  constructor(
    private readonly productNameService: ProductNameService,
    private readonly productsService: ProductsService,
    private urlService: UrlService
  ) {}

  ngOnDestroy(): void {
    this.productsSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.magentaSubscriptionUrl = (window as any).magentaSubscriptionUrl;

    const product$ = combineLatest([
      this.productsService.getSubscriptionForCustomer(),
      this.productNameService.productName()
    ]);

    this.productsSubscription = product$.subscribe(
      ([fixedSubscription, alias]) => {
        this.productName = alias;
        this.subscriptions = this.sortedSubscriptions(fixedSubscription);
      }
    );
  }

  openMagentaAccount(subscription: MagentaSubscriptionModel): void {
    if (!subscription.isFixedSubscription) {
      this.urlService.setLocationHref(`${this.magentaSubscriptionUrl}${encodeURIComponent(subscription.vkfe)}`);
    }
  }

  subscriptionIcon(iconType: number): string {
    switch (iconType) {
      case SubscriptionIconType.PrePaid:
      case SubscriptionIconType.PostPaid:
        return 'icon-smartphone';
      case SubscriptionIconType.Fixed1p:
        return '/assets/images/shop/1p-subscription.svg';
      case SubscriptionIconType.Fixed2pTv:
        return '/assets/images/shop/2p-tv-subscription.svg';
      case SubscriptionIconType.Fixed2pVoip:
        return '/assets/images/shop/2p-voip-subscription.svg';
      case SubscriptionIconType.Fixed3p:
        return '/assets/images/shop/3p-subscription.svg';
    }

    return 'icon-mobile-home';
  }

  private fixedSubscriptionModel(subscription: SubscriptionModel): MagentaSubscriptionModel | null {
    if (!this.account) {
      return null;
    }

    const iconType = this.productNameService.subscriptionIconType(subscription);

    return {
      alias: this.account.magentaAlias ? this.account.magentaAlias : this.productName,
      iconType,
      isFixedSubscription: true,
      vkfe: this.account.magentaVkfe
    };
  }

  private sortedSubscriptions(subscription: SubscriptionModel): MagentaSubscriptionModel[] {
    const fixedSubscriptionModel = this.fixedSubscriptionModel(subscription);

    if (!this.account?.magentaSubscriptions?.length || !fixedSubscriptionModel) {
      return [];
    }

    const joinedSubscriptions = [...this.account.magentaSubscriptions, fixedSubscriptionModel];

    return (
      joinedSubscriptions.sort((a, b) => {
        if (a.alias.toUpperCase() < b.alias.toUpperCase()) {
          return -1;
        }
        if (a.alias.toUpperCase() > b.alias.toUpperCase()) {
          return 1;
        }
        return 0;
      }) ?? []
    );
  }
}
