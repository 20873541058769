export class MagentaSubscriptionModel {
  alias: string;
  iconType: SubscriptionIconType;
  isFixedSubscription: boolean;
  vkfe: string;
}

export enum SubscriptionIconType {
  // mobile
  PrePaid = 0,
  PostPaid = 1,
  // fixed
  Fixed1p = 10,
  Fixed2pVoip = 11,
  Fixed2pTv = 12,
  Fixed3p = 13
}
