<section class="section section-square-pattern">
  <div class="container maintenanceMessage">
    <h2>
      <b> My T-Mobile Thuis tijdelijk niet beschikbaar </b>
    </h2>
    <p>
      Om je altijd de beste ervaring te geven op onze website zijn we druk bezig met onderhoud. Hierdoor kun je even geen gebruik
      maken van My T-Mobile Thuis. We zijn zo snel mogelijk weer beschikbaar.
    </p>
    <p>
      Heb je vragen? We hebben ook veel antwoorden al voor je online gezet op
      <a href="https://www.odido.nl/service" class="text-nowrap">Odido Service.</a>
    </p>
    <svg
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1536.2 755.1"
      style="enable-background: new 0 0 1536.2 755.1"
      xml:space="preserve"
      sodipodi:docname="loader.svg"
      inkscape:version="0.92.1 r15371"
    >
      <metadata id="metadata4894"></metadata>
      <defs id="defs4892" />
      <style type="text/css" id="style3680">
        .st0 {
          fill: #e6e7e8;
        }
        .st1 {
          fill: #d1d3d4;
        }
        .st2 {
          fill: #f4f4f4;
        }
        .st3 {
          fill: #bcbec0;
        }
        .st4 {
          fill: #231f20;
        }
        .st5 {
          fill: #ffffff;
        }
        .st6 {
          fill: #d36866;
        }
        .st7 {
          fill: #c2c4c6;
        }
        .st8 {
          fill: #dbdbdb;
        }
        .st9 {
          fill: #a7a9ac;
        }
        .st10 {
          fill: #808285;
        }
      </style>
      <rect
        x="96.7"
        y="27.1"
        class="st0"
        width="157.4"
        height="471.6"
        id="rect3682"
        style="fill: #e30074; fill-opacity: 1; opacity: 0.66"
      />
      <rect
        x="458.3"
        class="st0"
        width="157.4"
        height="471.6"
        id="rect3684"
        style="fill: #e30074; fill-opacity: 1; opacity: 0.66"
      />
      <polygon
        class="st1"
        points="489.6,475.3 219.3,475.3 219.3,72.3 216,68.8 216,51.3 464,51.3 489.6,90.7 "
        id="polygon3686"
        style="fill: #e30074; fill-opacity: 1"
      />
      <path
        class="st2"
        d="M1482.2,755.1H54c-36.8,0-62.8-36.1-51.2-71l36.3-108.5h1458l36.3,108.5C1545,719,1519,755.1,1482.2,755.1z"
        id="path3688"
      />
      <g id="g4056">
        <g id="g3854">
          <g id="g3770">
            <g id="g3698">
              <polygon class="st1" points="95.8,582.5 77.8,582.5 77.8,452.9 86.8,438.5 95.8,452.9     " id="polygon3690" />
              <path
                class="st3"
                d="M84.4,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C86.5,552.3,84.5,582.1,84.4,583.4z"
                id="path3692"
              />
              <path
                class="st3"
                d="M89.5,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C90.6,499.1,92.5,546.6,89.5,583.4z"
                id="path3694"
              />
              <path
                class="st4"
                d="M98.3,585h-23V452.2l11.5-18.4l11.5,18.4V585z M80.3,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3696"
              />
            </g>
            <g id="g3708">
              <polygon class="st1" points="114.3,582.5 96.3,582.5 96.3,452.9 105.3,438.5 114.3,452.9     " id="polygon3700" />
              <path
                class="st3"
                d="M102.9,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C105,552.3,103,582.1,102.9,583.4z"
                id="path3702"
              />
              <path
                class="st3"
                d="M108,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.4c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C109.1,499.1,111,546.6,108,583.4z"
                id="path3704"
              />
              <path
                class="st4"
                d="M116.8,585h-23V452.2l11.5-18.4l11.5,18.4V585z M98.8,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3706"
              />
            </g>
            <g id="g3718">
              <polygon class="st1" points="132.8,582.5 114.8,582.5 114.8,452.9 123.8,438.5 132.8,452.9     " id="polygon3710" />
              <path
                class="st3"
                d="M121.4,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C123.5,552.3,121.5,582.1,121.4,583.4z"
                id="path3712"
              />
              <path
                class="st3"
                d="M126.5,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C127.6,499.1,129.6,546.6,126.5,583.4z"
                id="path3714"
              />
              <path
                class="st4"
                d="M135.3,585h-23V452.2l11.5-18.4l11.5,18.4V585z M117.3,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3716"
              />
            </g>
            <g id="g3728">
              <polygon class="st1" points="151.3,582.5 133.3,582.5 133.3,452.9 142.3,438.5 151.3,452.9     " id="polygon3720" />
              <path
                class="st3"
                d="M139.9,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C142.1,552.3,140,582.1,139.9,583.4z"
                id="path3722"
              />
              <path
                class="st3"
                d="M145,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C146.1,499.1,148.1,546.6,145,583.4z"
                id="path3724"
              />
              <path
                class="st4"
                d="M153.8,585h-23V452.2l11.5-18.4l11.5,18.4V585z M135.8,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3726"
              />
            </g>
            <g id="g3738">
              <polygon class="st1" points="169.3,582.5 151.3,582.5 151.3,452.9 160.3,438.5 169.3,452.9     " id="polygon3730" />
              <path
                class="st3"
                d="M157.9,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C160.1,552.3,158,582.1,157.9,583.4z"
                id="path3732"
              />
              <path
                class="st3"
                d="M163,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C164.1,499.1,166.1,546.6,163,583.4z"
                id="path3734"
              />
              <path
                class="st4"
                d="M171.8,585h-23V452.2l11.5-18.4l11.5,18.4V585z M153.8,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3736"
              />
            </g>
            <g id="g3748">
              <polygon class="st1" points="187.8,582.5 169.8,582.5 169.8,452.9 178.8,438.5 187.8,452.9     " id="polygon3740" />
              <path
                class="st3"
                d="M176.4,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C178.6,552.3,176.5,582.1,176.4,583.4z"
                id="path3742"
              />
              <path
                class="st3"
                d="M181.5,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C182.7,499.1,184.6,546.6,181.5,583.4z"
                id="path3744"
              />
              <path
                class="st4"
                d="M190.3,585h-23V452.2l11.5-18.4l11.5,18.4V585z M172.3,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3746"
              />
            </g>
            <g id="g3758">
              <polygon class="st1" points="206.3,582.5 188.3,582.5 188.3,452.9 197.3,438.5 206.3,452.9     " id="polygon3750" />
              <path
                class="st3"
                d="M194.9,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C197.1,552.3,195,582.1,194.9,583.4z"
                id="path3752"
              />
              <path
                class="st3"
                d="M200,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C201.2,499.1,203.1,546.6,200,583.4z"
                id="path3754"
              />
              <path
                class="st4"
                d="M208.8,585h-23V452.2l11.5-18.4l11.5,18.4V585z M190.8,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3756"
              />
            </g>
            <g id="g3768">
              <polygon class="st1" points="224.8,582.5 206.8,582.5 206.8,452.9 215.8,438.5 224.8,452.9     " id="polygon3760" />
              <path
                class="st3"
                d="M213.5,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C215.6,552.3,213.5,582.1,213.5,583.4z"
                id="path3762"
              />
              <path
                class="st3"
                d="M218.5,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.4c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C219.7,499.1,221.6,546.6,218.5,583.4z"
                id="path3764"
              />
              <path
                class="st4"
                d="M227.3,585h-23V452.2l11.5-18.4l11.5,18.4V585z M209.3,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3766"
              />
            </g>
          </g>
          <g id="g3852">
            <g id="g3780">
              <polygon class="st1" points="243.1,582.5 225.1,582.5 225.1,452.9 234.1,438.5 243.1,452.9     " id="polygon3772" />
              <path
                class="st3"
                d="M231.8,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C233.9,552.3,231.8,582.1,231.8,583.4z"
                id="path3774"
              />
              <path
                class="st3"
                d="M236.8,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C238,499.1,239.9,546.6,236.8,583.4z"
                id="path3776"
              />
              <path
                class="st4"
                d="M245.6,585h-23V452.2l11.5-18.4l11.5,18.4V585z M227.6,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3778"
              />
            </g>
            <g id="g3790">
              <polygon class="st1" points="261.6,582.5 243.6,582.5 243.6,452.9 252.6,438.5 261.6,452.9     " id="polygon3782" />
              <path
                class="st3"
                d="M250.3,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C252.4,552.3,250.3,582.1,250.3,583.4z"
                id="path3784"
              />
              <path
                class="st3"
                d="M255.3,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C256.5,499.1,258.4,546.6,255.3,583.4z"
                id="path3786"
              />
              <path
                class="st4"
                d="M264.1,585h-23V452.2l11.5-18.4l11.5,18.4V585z M246.1,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3788"
              />
            </g>
            <g id="g3800">
              <polygon class="st1" points="280.1,582.5 262.1,582.5 262.1,452.9 271.1,438.5 280.1,452.9     " id="polygon3792" />
              <path
                class="st3"
                d="M268.8,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C270.9,552.3,268.9,582.1,268.8,583.4z"
                id="path3794"
              />
              <path
                class="st3"
                d="M273.8,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C275,499.1,276.9,546.6,273.8,583.4z"
                id="path3796"
              />
              <path
                class="st4"
                d="M282.6,585h-23V452.2l11.5-18.4l11.5,18.4V585z M264.6,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3798"
              />
            </g>
            <g id="g3810">
              <polygon class="st1" points="298.6,582.5 280.6,582.5 280.6,452.9 289.6,438.5 298.6,452.9     " id="polygon3802" />
              <path
                class="st3"
                d="M287.3,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C289.4,552.3,287.4,582.1,287.3,583.4z"
                id="path3804"
              />
              <path
                class="st3"
                d="M292.3,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.4c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C293.5,499.1,295.4,546.6,292.3,583.4z"
                id="path3806"
              />
              <path
                class="st4"
                d="M301.1,585h-23V452.2l11.5-18.4l11.5,18.4V585z M283.1,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3808"
              />
            </g>
            <g id="g3820">
              <polygon class="st1" points="316.6,582.5 298.6,582.5 298.6,452.9 307.6,438.5 316.6,452.9     " id="polygon3812" />
              <path
                class="st3"
                d="M305.3,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C307.4,552.3,305.4,582.1,305.3,583.4z"
                id="path3814"
              />
              <path
                class="st3"
                d="M310.3,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.4c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C311.5,499.1,313.4,546.6,310.3,583.4z"
                id="path3816"
              />
              <path
                class="st4"
                d="M319.1,585h-23V452.2l11.5-18.4l11.5,18.4V585z M301.1,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3818"
              />
            </g>
            <g id="g3830">
              <polygon class="st1" points="335.1,582.5 317.1,582.5 317.1,452.9 326.1,438.5 335.1,452.9     " id="polygon3822" />
              <path
                class="st3"
                d="M323.8,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C325.9,552.3,323.9,582.1,323.8,583.4z"
                id="path3824"
              />
              <path
                class="st3"
                d="M328.8,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C330,499.1,331.9,546.6,328.8,583.4z"
                id="path3826"
              />
              <path
                class="st4"
                d="M337.6,585h-23V452.2l11.5-18.4l11.5,18.4V585z M319.6,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3828"
              />
            </g>
            <g id="g3840">
              <polygon class="st1" points="353.6,582.5 335.6,582.5 335.6,452.9 344.6,438.5 353.6,452.9     " id="polygon3832" />
              <path
                class="st3"
                d="M342.3,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C344.4,552.3,342.4,582.1,342.3,583.4z"
                id="path3834"
              />
              <path
                class="st3"
                d="M347.3,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C348.5,499.1,350.4,546.6,347.3,583.4z"
                id="path3836"
              />
              <path
                class="st4"
                d="M356.1,585h-23V452.2l11.5-18.4l11.5,18.4V585z M338.1,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3838"
              />
            </g>
            <g id="g3850">
              <polygon class="st1" points="372.1,582.5 354.1,582.5 354.1,452.9 363.1,438.5 372.1,452.9     " id="polygon3842" />
              <path
                class="st3"
                d="M360.8,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C362.9,552.3,360.9,582.1,360.8,583.4z"
                id="path3844"
              />
              <path
                class="st3"
                d="M365.8,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C367,499.1,368.9,546.6,365.8,583.4z"
                id="path3846"
              />
              <path
                class="st4"
                d="M374.6,585h-23V452.2l11.5-18.4l11.5,18.4V585z M356.6,580h13V453.6l-6.5-10.4l-6.5,10.4V580z"
                id="path3848"
              />
            </g>
          </g>
        </g>
        <g id="g4020">
          <g id="g3936">
            <g id="g3864">
              <polygon class="st1" points="390.6,582.4 372.6,582.4 372.6,452.8 381.6,438.4 390.6,452.8     " id="polygon3856" />
              <path
                class="st3"
                d="M379.2,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C381.4,552.2,379.3,582.1,379.2,583.3z"
                id="path3858"
              />
              <path
                class="st3"
                d="M384.3,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C385.5,499,387.4,546.5,384.3,583.3z"
                id="path3860"
              />
              <path
                class="st4"
                d="M393.1,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M375.1,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3862"
              />
            </g>
            <g id="g3874">
              <polygon class="st1" points="409.1,582.4 391.1,582.4 391.1,452.8 400.1,438.4 409.1,452.8     " id="polygon3866" />
              <path
                class="st3"
                d="M397.7,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C399.9,552.2,397.8,582.1,397.7,583.3z"
                id="path3868"
              />
              <path
                class="st3"
                d="M402.8,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C404,499,405.9,546.5,402.8,583.3z"
                id="path3870"
              />
              <path
                class="st4"
                d="M411.6,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M393.6,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3872"
              />
            </g>
            <g id="g3884">
              <polygon class="st1" points="427.6,582.4 409.6,582.4 409.6,452.8 418.6,438.4 427.6,452.8     " id="polygon3876" />
              <path
                class="st3"
                d="M416.3,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C418.4,552.2,416.3,582.1,416.3,583.3z"
                id="path3878"
              />
              <path
                class="st3"
                d="M421.3,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C422.5,499,424.4,546.5,421.3,583.3z"
                id="path3880"
              />
              <path
                class="st4"
                d="M430.1,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M412.1,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3882"
              />
            </g>
            <g id="g3894">
              <polygon class="st1" points="446.1,582.4 428.1,582.4 428.1,452.8 437.1,438.4 446.1,452.8     " id="polygon3886" />
              <path
                class="st3"
                d="M434.8,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C436.9,552.2,434.8,582.1,434.8,583.3z"
                id="path3888"
              />
              <path
                class="st3"
                d="M439.8,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C441,499,442.9,546.5,439.8,583.3z"
                id="path3890"
              />
              <path
                class="st4"
                d="M448.6,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M430.6,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3892"
              />
            </g>
            <g id="g3904">
              <polygon class="st1" points="464.1,582.4 446.1,582.4 446.1,452.8 455.1,438.4 464.1,452.8     " id="polygon3896" />
              <path
                class="st3"
                d="M452.8,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C454.9,552.2,452.8,582.1,452.8,583.3z"
                id="path3898"
              />
              <path
                class="st3"
                d="M457.8,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C459,499,460.9,546.5,457.8,583.3z"
                id="path3900"
              />
              <path
                class="st4"
                d="M466.6,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M448.6,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3902"
              />
            </g>
            <g id="g3914">
              <polygon class="st1" points="482.6,582.4 464.6,582.4 464.6,452.8 473.6,438.4 482.6,452.8     " id="polygon3906" />
              <path
                class="st3"
                d="M471.3,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C473.4,552.2,471.4,582.1,471.3,583.3z"
                id="path3908"
              />
              <path
                class="st3"
                d="M476.3,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C477.5,499,479.4,546.5,476.3,583.3z"
                id="path3910"
              />
              <path
                class="st4"
                d="M485.1,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M467.1,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3912"
              />
            </g>
            <g id="g3924">
              <polygon class="st1" points="501.1,582.4 483.1,582.4 483.1,452.8 492.1,438.4 501.1,452.8     " id="polygon3916" />
              <path
                class="st3"
                d="M489.8,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C491.9,552.2,489.9,582.1,489.8,583.3z"
                id="path3918"
              />
              <path
                class="st3"
                d="M494.8,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C496,499,497.9,546.5,494.8,583.3z"
                id="path3920"
              />
              <path
                class="st4"
                d="M503.6,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M485.6,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3922"
              />
            </g>
            <g id="g3934">
              <polygon class="st1" points="519.6,582.4 501.6,582.4 501.6,452.8 510.6,438.4 519.6,452.8     " id="polygon3926" />
              <path
                class="st3"
                d="M508.3,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C510.4,552.2,508.4,582.1,508.3,583.3z"
                id="path3928"
              />
              <path
                class="st3"
                d="M513.3,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C514.5,499,516.4,546.5,513.3,583.3z"
                id="path3930"
              />
              <path
                class="st4"
                d="M522.1,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M504.1,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3932"
              />
            </g>
          </g>
          <g id="g4018">
            <g id="g3946">
              <polygon class="st1" points="537.9,582.4 519.9,582.4 519.9,452.8 528.9,438.4 537.9,452.8     " id="polygon3938" />
              <path
                class="st3"
                d="M526.6,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C528.7,552.2,526.7,582.1,526.6,583.3z"
                id="path3940"
              />
              <path
                class="st3"
                d="M531.6,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C532.8,499,534.7,546.5,531.6,583.3z"
                id="path3942"
              />
              <path
                class="st4"
                d="M540.4,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M522.4,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3944"
              />
            </g>
            <g id="g3956">
              <polygon class="st1" points="556.4,582.4 538.4,582.4 538.4,452.8 547.4,438.4 556.4,452.8     " id="polygon3948" />
              <path
                class="st3"
                d="M545.1,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C547.2,552.2,545.2,582.1,545.1,583.3z"
                id="path3950"
              />
              <path
                class="st3"
                d="M550.1,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C551.3,499,553.2,546.5,550.1,583.3z"
                id="path3952"
              />
              <path
                class="st4"
                d="M558.9,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M540.9,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3954"
              />
            </g>
            <g id="g3966">
              <polygon class="st1" points="574.9,582.4 556.9,582.4 556.9,452.8 565.9,438.4 574.9,452.8     " id="polygon3958" />
              <path
                class="st3"
                d="M563.6,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C565.7,552.2,563.7,582.1,563.6,583.3z"
                id="path3960"
              />
              <path
                class="st3"
                d="M568.6,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C569.8,499,571.7,546.5,568.6,583.3z"
                id="path3962"
              />
              <path
                class="st4"
                d="M577.4,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M559.4,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3964"
              />
            </g>
            <g id="g3976">
              <polygon class="st1" points="593.4,582.4 575.4,582.4 575.4,452.8 584.4,438.4 593.4,452.8     " id="polygon3968" />
              <path
                class="st3"
                d="M582.1,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C584.2,552.2,582.2,582.1,582.1,583.3z"
                id="path3970"
              />
              <path
                class="st3"
                d="M587.1,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C588.3,499,590.2,546.5,587.1,583.3z"
                id="path3972"
              />
              <path
                class="st4"
                d="M595.9,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M577.9,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3974"
              />
            </g>
            <g id="g3986">
              <polygon class="st1" points="611.4,582.4 593.4,582.4 593.4,452.8 602.4,438.4 611.4,452.8     " id="polygon3978" />
              <path
                class="st3"
                d="M600.1,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C602.2,552.2,600.2,582.1,600.1,583.3z"
                id="path3980"
              />
              <path
                class="st3"
                d="M605.1,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C606.3,499,608.2,546.5,605.1,583.3z"
                id="path3982"
              />
              <path
                class="st4"
                d="M613.9,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M595.9,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3984"
              />
            </g>
            <g id="g3996">
              <polygon class="st1" points="630,582.4 612,582.4 612,452.8 621,438.4 630,452.8     " id="polygon3988" />
              <path
                class="st3"
                d="M618.6,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C620.7,552.2,618.7,582.1,618.6,583.3z"
                id="path3990"
              />
              <path
                class="st3"
                d="M623.6,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C624.8,499,626.7,546.5,623.6,583.3z"
                id="path3992"
              />
              <path
                class="st4"
                d="M632.5,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M614.5,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path3994"
              />
            </g>
            <g id="g4006">
              <polygon class="st1" points="648.5,582.4 630.5,582.4 630.5,452.8 639.5,438.4 648.5,452.8     " id="polygon3998" />
              <path
                class="st3"
                d="M637.1,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C639.2,552.2,637.2,582.1,637.1,583.3z"
                id="path4000"
              />
              <path
                class="st3"
                d="M642.2,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.4c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C643.3,499.1,645.2,546.5,642.2,583.3z"
                id="path4002"
              />
              <path
                class="st4"
                d="M651,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M633,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path4004"
              />
            </g>
            <g id="g4016">
              <polygon class="st1" points="667,582.4 649,582.4 649,452.8 658,438.4 667,452.8     " id="polygon4008" />
              <path
                class="st3"
                d="M655.6,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C657.7,552.2,655.7,582.1,655.6,583.3z"
                id="path4010"
              />
              <path
                class="st3"
                d="M660.7,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C661.8,499,663.7,546.5,660.7,583.3z"
                id="path4012"
              />
              <path
                class="st4"
                d="M669.5,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M651.5,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path4014"
              />
            </g>
          </g>
        </g>
        <g id="g4054">
          <g id="g4052">
            <g id="g4030">
              <polygon class="st1" points="685.5,582.4 667.5,582.4 667.5,452.8 676.5,438.4 685.5,452.8     " id="polygon4022" />
              <path
                class="st3"
                d="M674.2,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C676.3,552.2,674.3,582.1,674.2,583.3z"
                id="path4024"
              />
              <path
                class="st3"
                d="M679.2,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C680.4,499,682.3,546.5,679.2,583.3z"
                id="path4026"
              />
              <path
                class="st4"
                d="M688,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M670,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path4028"
              />
            </g>
            <g id="g4040">
              <polygon class="st1" points="704.1,582.4 686.1,582.4 686.1,452.8 695.1,438.4 704.1,452.8     " id="polygon4032" />
              <path
                class="st3"
                d="M692.7,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C694.8,552.2,692.8,582.1,692.7,583.3z"
                id="path4034"
              />
              <path
                class="st3"
                d="M697.7,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C698.9,499,700.8,546.5,697.7,583.3z"
                id="path4036"
              />
              <path
                class="st4"
                d="M706.6,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M688.6,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path4038"
              />
            </g>
            <g id="g4050">
              <polygon class="st1" points="722.6,582.4 704.6,582.4 704.6,452.8 713.6,438.4 722.6,452.8     " id="polygon4042" />
              <path
                class="st3"
                d="M711.2,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1      c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C713.3,552.2,711.3,582.1,711.2,583.3z"
                id="path4044"
              />
              <path
                class="st3"
                d="M716.3,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1      c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C717.4,499,719.3,546.5,716.3,583.3z"
                id="path4046"
              />
              <path
                class="st4"
                d="M725.1,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M707.1,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z"
                id="path4048"
              />
            </g>
          </g>
        </g>
      </g>
      <polygon
        class="st0"
        points="1365.3,331.9 1164.2,331.9 1164.2,0 1325.1,0 1365.3,38.1 "
        id="polygon4058"
        style="fill: #e30074; fill-opacity: 1; opacity: 0.47"
      />
      <path
        class="st1"
        d="M1122.3,51.3v-3.2c1.8-1,3-3,3-5.2c0-3.3-2.7-6-6-6s-6,2.7-6,6c0,2.2,1.2,4.2,3,5.2v3.2H1006V5.7h-29.4  l-16.2,19v26.6l-53.2,0v9.9l3.6,2.1v268.5h270.3v-267l3.2-2V51.3H1122.3z"
        id="path4060"
        style="fill: #e30074; fill-opacity: 1; opacity: 1"
      />
      <g id="g4066">
        <polyline class="st5" points="944.3,152.8 884,152.8 884,165.9 931.4,165.9  " id="polyline4062" />
        <polygon
          class="st4"
          points="931.4,168.4 881.5,168.4 881.5,150.3 944.3,150.3 944.3,155.3 886.5,155.3 886.5,163.4 931.4,163.4     "
          id="polygon4064"
        />
      </g>
      <g id="g4072">
        <polygon
          class="st6"
          points="1406.1,318.7 1350.7,264.7 759.5,264.7 704.1,318.7  "
          id="polygon4068"
          style="fill: #e30074; fill-opacity: 1"
        />
        <path
          class="st4"
          d="M1412.2,321.2H697.9l60.6-59h593.2L1412.2,321.2z M710.2,316.2h689.7l-50.3-49H760.5L710.2,316.2z"
          id="path4070"
        />
      </g>
      <g id="g4078">
        <polygon
          class="st1"
          points="722.1,345.7 713.1,336.7 713.1,318.7 1397.1,318.7 1397.1,336.7 1388.1,345.7  "
          id="polygon4074"
        />
        <path
          class="st4"
          d="M1389.1,348.2H721l-10.5-10.5v-21.5h689v21.5L1389.1,348.2z M723.1,343.2H1387l7.5-7.5v-14.5h-679v14.5   L723.1,343.2z"
          id="path4076"
        />
      </g>
      <g id="g4084">
        <rect x="722.1" y="345.7" class="st5" width="666" height="234" id="rect4080" />
        <path class="st4" d="M1390.6,582.2h-671v-239h671V582.2z M724.6,577.2h661v-229h-661V577.2z" id="path4082" />
      </g>
      <polygon
        class="st4"
        points="913.6,579.7 908.6,579.7 908.6,465.2 841.6,465.2 841.6,579.7 836.6,579.7 836.6,460.2 913.6,460.2 "
        id="polygon4086"
      />
      <g id="g4104">
        <rect x="760.4" y="412.2" class="st4" width="99.7" height="5" id="rect4088" />
        <g id="g4102">
          <rect x="760.4" y="384.2" class="st4" width="99.7" height="5" id="rect4090" />
          <rect x="740.4" y="398.2" class="st4" width="106.7" height="5" id="rect4092" />
          <rect x="790.9" y="386.7" class="st4" width="5" height="14" id="rect4094" />
          <rect x="820.9" y="400.7" class="st4" width="5" height="14" id="rect4096" />
          <rect x="767.6" y="400.7" class="st4" width="5" height="14" id="rect4098" />
          <rect x="829.9" y="386.7" class="st4" width="5" height="14" id="rect4100" />
        </g>
      </g>
      <g id="g4122">
        <rect x="962.8" y="468.2" class="st4" width="99.7" height="5" id="rect4106" />
        <g id="g4120">
          <rect x="962.8" y="440.2" class="st4" width="99.7" height="5" id="rect4108" />
          <rect x="942.8" y="454.2" class="st4" width="106.7" height="5" id="rect4110" />
          <rect x="993.3" y="442.7" class="st4" width="5" height="14" id="rect4112" />
          <rect x="1023.3" y="456.7" class="st4" width="5" height="14" id="rect4114" />
          <rect x="969.9" y="456.7" class="st4" width="5" height="14" id="rect4116" />
          <rect x="1032.3" y="442.7" class="st4" width="5" height="14" id="rect4118" />
        </g>
      </g>
      <g id="g4140">
        <rect x="1276" y="523.6" class="st4" width="99.7" height="5" id="rect4124" />
        <g id="g4138">
          <rect x="1276" y="495.6" class="st4" width="99.7" height="5" id="rect4126" />
          <rect x="1256" y="509.6" class="st4" width="106.7" height="5" id="rect4128" />
          <rect x="1306.5" y="498.1" class="st4" width="5" height="14" id="rect4130" />
          <rect x="1336.5" y="512.1" class="st4" width="5" height="14" id="rect4132" />
          <rect x="1283.2" y="512.1" class="st4" width="5" height="14" id="rect4134" />
          <rect x="1345.5" y="498.1" class="st4" width="5" height="14" id="rect4136" />
        </g>
      </g>
      <g id="g4146">
        <rect x="586.6" y="377.1" class="st0" width="29.1" height="246.4" id="rect4142" />
        <path class="st4" d="M618.2,626h-34.1V374.6h34.1V626z M589.1,621h24.1V379.6h-24.1V621z" id="path4144" />
      </g>
      <g id="g4152">
        <rect x="191.7" y="378.3" class="st0" width="29.1" height="246.4" id="rect4148" />
        <path class="st4" d="M223.2,627.2h-34.1V375.8h34.1V627.2z M194.2,622.2h24.1V380.8h-24.1V622.2z" id="path4150" />
      </g>
      <path
        class="st4"
        d="M1188.3,484.5l-1.7-2.1l-245.8-304l16.2-15.7l299.8,255.2L1188.3,484.5z M947.6,178.7L1188.8,477l60.5-59  L957.2,169.3L947.6,178.7z"
        id="path4154"
      />
      <rect
        x="1083.2"
        y="117.3"
        transform="matrix(0.716 -0.6981 0.6981 0.716 91.3557 846.1431)"
        class="st4"
        width="5"
        height="387"
        id="rect4156"
      />
      <polygon
        class="st4"
        points="1186.1,481.3 1167.2,394.4 1253.6,415.5 1252.4,420.4 1173.8,401.2 1191,480.2 "
        id="polygon4158"
      />
      <path
        class="st4"
        d="M1174.9,402.3l-78.8-32.5l-0.4-1l-30.1-78.6l78.8,32.5L1174.9,402.3z M1100,366l66.1,27.3l-25.6-66.8  l-66.1-27.3L1100,366z"
        id="path4160"
      />
      <path
        class="st4"
        d="M1077.2,302.1l-72.2-44.6l-0.3-0.5l-42.4-72.8l72.2,44.6l0.3,0.5L1077.2,302.1z M1008.7,253.9l54.1,33.4  l-32-54.9l-54.1-33.4L1008.7,253.9z"
        id="path4162"
      />
      <path
        class="st4"
        d="M975,197.2l-32.3-16.7l-14.8-15.2l14.6-14.2l0.7-0.7l2.2-0.1l0.8,0.8l13.1,13.4L975,197.2z M945.7,176.5  l18.3,9.4l-9-18.5l-10.8-11l-9.3,9.1L945.7,176.5z"
        id="path4164"
      />
      <g id="g4170">
        <path
          class="st7"
          d="M1310,624.7h-247.5c-18.2,0-33-14.8-33-33v0c0-18.2,14.8-33,33-33H1310c18.2,0,33,14.8,33,33v0   C1343,610,1328.2,624.7,1310,624.7z"
          id="path4166"
        />
        <path
          class="st4"
          d="M1310,627.2h-247.5c-19.6,0-35.5-15.9-35.5-35.5s15.9-35.5,35.5-35.5H1310c19.6,0,35.5,15.9,35.5,35.5   S1329.6,627.2,1310,627.2z M1062.5,561.2c-16.8,0-30.5,13.7-30.5,30.5s13.7,30.5,30.5,30.5H1310c16.8,0,30.5-13.7,30.5-30.5   s-13.7-30.5-30.5-30.5H1062.5z"
          id="path4168"
        />
      </g>
      <g id="g4208">
        <g id="g4176">
          <ellipse class="st8" cx="1062.5" cy="591.2" rx="16.5" ry="15.9" id="ellipse4172" />
          <path
            class="st4"
            d="M1062.5,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1073,609.6,1062.5,609.6z     M1062.5,577.7c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1070.2,577.7,1062.5,577.7z"
            id="path4174"
          />
        </g>
        <g id="g4182">
          <ellipse class="st8" cx="1310" cy="591.2" rx="16.5" ry="15.9" id="ellipse4178" />
          <path
            class="st4"
            d="M1310,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1320.5,609.6,1310,609.6z M1310,577.7    c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1317.7,577.7,1310,577.7z"
            id="path4180"
          />
        </g>
        <g id="g4188">
          <ellipse class="st8" cx="1260.5" cy="591.2" rx="16.5" ry="15.9" id="ellipse4184" />
          <path
            class="st4"
            d="M1260.5,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1271,609.6,1260.5,609.6z     M1260.5,577.7c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1268.2,577.7,1260.5,577.7z"
            id="path4186"
          />
        </g>
        <g id="g4194">
          <ellipse class="st8" cx="1211" cy="591.2" rx="16.5" ry="15.9" id="ellipse4190" />
          <path
            class="st4"
            d="M1211,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1221.5,609.6,1211,609.6z M1211,577.7    c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1218.7,577.7,1211,577.7z"
            id="path4192"
          />
        </g>
        <g id="g4200">
          <ellipse class="st8" cx="1161.5" cy="591.2" rx="16.5" ry="15.9" id="ellipse4196" />
          <path
            class="st4"
            d="M1161.5,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1172,609.6,1161.5,609.6z     M1161.5,577.7c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1169.2,577.7,1161.5,577.7z"
            id="path4198"
          />
        </g>
        <g id="g4206">
          <ellipse class="st8" cx="1112" cy="591.2" rx="16.5" ry="15.9" id="ellipse4202" />
          <path
            class="st4"
            d="M1112,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1122.5,609.6,1112,609.6z M1112,577.7    c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1119.7,577.7,1112,577.7z"
            id="path4204"
          />
        </g>
      </g>
      <g id="g4214">
        <polygon class="st3" points="1310,534 1310,451.5 1285.3,426.7 1219.3,426.7 1219.3,534  " id="polygon4210" />
        <path
          class="st4"
          d="M1312.5,536.5h-95.8V424.2h69.5l26.2,26.2V536.5z M1221.8,531.5h85.8v-79l-23.3-23.3h-62.5V531.5z"
          id="path4212"
        />
      </g>
      <g id="g4220">
        <rect x="1219.3" y="534" class="st0" width="90.8" height="24.8" id="rect4216" />
        <path class="st4" d="M1312.5,561.2h-95.8v-29.8h95.8V561.2z M1221.8,556.2h85.8v-19.8h-85.8V556.2z" id="path4218" />
      </g>
      <g id="g4226">
        <polygon class="st0" points="1070.8,558.7 1046,534 1219.3,534 1219.3,558.7  " id="polygon4222" />
        <path
          class="st4"
          d="M1221.8,561.2h-152l-29.8-29.8h181.8V561.2z M1071.8,556.2h145v-19.8H1052L1071.8,556.2z"
          id="path4224"
        />
      </g>
      <g id="g4232">
        <polygon class="st5" points="1046,534 1037.8,525.7 1037.8,509.2 1079,426.7 1219.3,426.7 1219.3,534  " id="polygon4228" />
        <path
          class="st4"
          d="M1221.8,536.5H1045l-9.7-9.7v-18.1l0.3-0.5l41.9-83.9h144.3V536.5z M1047,531.5h169.7V429.2h-136.2l-40.3,80.6   v14.9L1047,531.5z"
          id="path4230"
        />
      </g>
      <path class="st4" d="M1131,511.7h-80.8l35.5-71h45.3V511.7z M1058.3,506.7h67.7v-61h-37.2L1058.3,506.7z" id="path4234" />
      <g id="g4240">
        <polygon class="st1" points="1109,426.7 1109,417.7 1118,408.7 1253,408.7 1262,417.7 1262,426.7  " id="polygon4236" />
        <path
          class="st4"
          d="M1264.5,429.2h-158v-12.5l10.5-10.5H1254l10.5,10.5V429.2z M1111.5,424.2h148v-5.5l-7.5-7.5H1119l-7.5,7.5   V424.2z"
          id="path4238"
        />
      </g>
      <g id="swinger">
        <polygon
          class="st6"
          points="734,352.7 1022,352.7 1022,361.7 1013,361.7 1013,379.7 1022,379.7 1022,388.7 806,388.7 734,388.7    734,379.7 743,379.7 743,361.7 734,361.7  "
          id="polygon4242"
          style="fill: #e30074; fill-opacity: 1"
        />
        <path
          class="st4"
          d="M1024.5,364.2v-14h-63.7l-72.4-117.7L816,350.2h-84.5v14h9v13h-9v14h293v-14h-9v-13H1024.5z M745.5,364.2h70.2   v13h-70.2V364.2z M820.7,364.2h135.4v13H820.7V364.2z M956.1,382.2v4H820.7v-4H956.1z M1010.5,377.2h-49.4v-13h49.4V377.2z    M820.7,359.2v-4h135.4v4H820.7z M888.4,242.1L955,350.2H821.9L888.4,242.1z M736.5,359.2v-4h79.2v4h-70.2H743H736.5z M736.5,382.2   h6.5h2.5h70.2v4h-79.2V382.2z M1019.5,382.2v4h-58.4v-4h49.4h2.5H1019.5z M1013,359.2h-2.5h-49.4v-4h58.4v4H1013z"
          id="path4244"
        />
      </g>
      <g id="g4277">
        <g id="g4251" transform="translate(0,40)">
          <rect x="151.7" y="165.3" class="st5" width="504" height="180" id="rect4247" style="fill: #ffffff" />
          <path
            class="st4"
            d="m 658.2,347.8 h -509 v -185 h 509 z m -504,-5 h 499 v -175 h -499 z"
            id="path4249"
            inkscape:connector-curvature="0"
            style="fill: #231f20"
          />
        </g>
      </g>
      <path
        class="st4"
        d="M1120.5,511.7h-23v-15.9c0-9.4,3.7-18.3,10.3-24.9l4.1-4.1c1.4-1.4,3.6-1.9,5.5-1.1c1.9,0.8,3.1,2.6,3.1,4.6  V511.7z M1102.5,506.7h13v-36.4l-4.1,4.1c-5.7,5.7-8.9,13.3-8.9,21.4V506.7z"
        id="path4279"
      />
      <rect x="1233" y="444.9" class="st10" width="17.5" height="70.9" id="rect4773" />
      <rect x="1256" y="444.9" class="st10" width="17.5" height="70.9" id="rect4775" />
      <path
        class="st4"
        d="M888.4,237c-6.2,0-11.3-5.1-11.3-11.3s5.1-11.3,11.3-11.3s11.3,5.1,11.3,11.3S894.6,237,888.4,237z   M888.4,219.4c-3.5,0-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3s6.3-2.8,6.3-6.3S891.9,219.4,888.4,219.4z"
        id="path4777"
      />
      <path
        class="st4"
        d="M888.4,219.4c-1.4,0-2.5-1.1-2.5-2.5v-50.9c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v50.9  C890.9,218.3,889.8,219.4,888.4,219.4z"
        id="path4779"
      />
      <g id="g4847">
        <g id="g4785">
          <rect x="704.2" y="593.3" class="st6" width="33.5" height="13.4" id="rect4781" style="fill: #e30074; fill-opacity: 1" />
          <path class="st4" d="M740.2,609.2h-38.5v-18.4h38.5V609.2z M706.7,604.2h28.5v-8.4h-28.5V604.2z" id="path4783" />
        </g>
        <g id="g4791">
          <rect x="744.4" y="593.3" class="st6" width="33.5" height="13.4" id="rect4787" style="fill: #e30074; fill-opacity: 1" />
          <path class="st4" d="M780.3,609.2h-38.5v-18.4h38.5V609.2z M746.9,604.2h28.5v-8.4h-28.5V604.2z" id="path4789" />
        </g>
        <g id="g4797">
          <rect x="751.1" y="580" class="st6" width="33.5" height="13.4" id="rect4793" style="fill: #e30074; fill-opacity: 1" />
          <path class="st4" d="M787,595.8h-38.5v-18.4H787V595.8z M753.6,590.8H782v-8.4h-28.5V590.8z" id="path4795" />
        </g>
        <g id="g4803">
          <rect x="710.9" y="580" class="st6" width="33.5" height="13.4" id="rect4799" style="fill: #e30074; fill-opacity: 1" />
          <path class="st4" d="M746.9,595.8h-38.5v-18.4h38.5V595.8z M713.4,590.8h28.5v-8.4h-28.5V590.8z" id="path4801" />
        </g>
        <g id="g4809">
          <rect x="704.2" y="566.6" class="st6" width="33.5" height="13.4" id="rect4805" style="fill: #e30074; fill-opacity: 1" />
          <path class="st4" d="M740.2,582.5h-38.5v-18.4h38.5V582.5z M706.7,577.5h28.5v-8.4h-28.5V577.5z" id="path4807" />
        </g>
        <g id="g4815">
          <rect x="744.4" y="566.6" class="st6" width="33.5" height="13.4" id="rect4811" style="fill: #e30074; fill-opacity: 1" />
          <path class="st4" d="M780.3,582.5h-38.5v-18.4h38.5V582.5z M746.9,577.5h28.5v-8.4h-28.5V577.5z" id="path4813" />
        </g>
        <g id="g4821">
          <rect x="751.1" y="553.2" class="st6" width="33.5" height="13.4" id="rect4817" style="fill: #e30074; fill-opacity: 1" />
          <path class="st4" d="M787,569.1h-38.5v-18.4H787V569.1z M753.6,564.1H782v-8.4h-28.5V564.1z" id="path4819" />
        </g>
        <g id="g4827">
          <rect x="697.5" y="553.2" class="st6" width="33.5" height="13.4" id="rect4823" style="fill: #e30074; fill-opacity: 1" />
          <path class="st4" d="M733.5,569.1H695v-18.4h38.5V569.1z M700,564.1h28.5v-8.4H700V564.1z" id="path4825" />
        </g>
        <g id="g4833">
          <rect x="724.3" y="539.8" class="st6" width="33.5" height="13.4" id="rect4829" style="fill: #e30074; fill-opacity: 1" />
          <path class="st4" d="M760.3,555.7h-38.5v-18.4h38.5V555.7z M726.8,550.7h28.5v-8.4h-28.5V550.7z" id="path4831" />
        </g>
        <g id="g4839">
          <rect x="791.2" y="593.3" class="st6" width="33.5" height="13.4" id="rect4835" style="fill: #e30074; fill-opacity: 1" />
          <path class="st4" d="M827.2,609.2h-38.5v-18.4h38.5V609.2z M793.7,604.2h28.5v-8.4h-28.5V604.2z" id="path4837" />
        </g>
        <g id="g4845">
          <rect
            x="670.6"
            y="581.7"
            transform="matrix(0.6117 -0.7911 0.7911 0.6117 -198.5944 772.2253)"
            class="st6"
            width="33.5"
            height="13.4"
            id="rect4841"
            style="fill: #e30074; fill-opacity: 1"
          />
          <path
            class="st4"
            d="M682.8,609.2L668.3,598l23.5-30.4l14.5,11.2L682.8,609.2z M675.3,597.1l6.6,5.1l17.4-22.5l-6.6-5.1    L675.3,597.1z"
            id="path4843"
          />
        </g>
      </g>
      <circle class="st4" cx="897.6" cy="521.2" r="4.5" id="circle4849" />
      <path
        inkscape:connector-curvature="0"
        id="path18"
        d="m 183.5436,288.33552 h 14.76804 v 15.07061 H 183.5436 Z"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <path
        inkscape:connector-curvature="0"
        style="fill: #ed008c; stroke-width: 0.91687071"
        id="path20"
        d="m 219.01091,258.25391 h 1.75214 c 11.31694,0 16.59169,6.0541 18.79585,22.19928 l 3.50428,-0.15496 -0.4676,-25.61553 h -57.58682 l -0.56754,25.61553 3.37225,0.15496 c 0.58404,-6.0541 1.28453,-9.31449 2.8047,-12.57489 2.68735,-6.05501 8.29585,-9.62439 15.18889,-9.62439 h 2.45354 v 55.73107 c 0,5.89915 -0.35024,7.76223 -1.75122,9.15954 -1.16901,1.08649 -3.50612,1.55226 -6.19438,1.55226 h -2.68735 v 3.72525 h 32.01621 v -3.72525 h -2.70385 c -2.67085,0 -5.02445,-0.46668 -6.17604,-1.55226 -1.4019,-1.39731 -1.75214,-3.26131 -1.75214,-9.15954 v -55.73107"
      />
      <path
        inkscape:connector-curvature="0"
        id="path22"
        d="m 228.72149,288.33552 h 14.76804 v 15.07061 h -14.76804 z"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <path
        inkscape:connector-curvature="0"
        id="path24"
        d="m 272.32878,288.33552 h 14.78454 v 15.07061 h -14.78454 z"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <path
        inkscape:connector-curvature="0"
        id="path26"
        d="m 316.64022,288.33552 h 14.76896 v 15.07061 h -14.76896 z"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <path
        inkscape:connector-curvature="0"
        id="path28"
        d="m 371.34348,328.42295 18.65832,-65.10333 v 51.08896 c 0,5.57549 -0.31724,7.53484 -1.58252,8.89089 -0.94987,1.0544 -3.15128,1.50734 -5.59199,1.50734 h -0.94896 v 3.61614 h 26.37378 v -3.61614 h -1.37255 c -2.42604,0 -4.6412,-0.45202 -5.59016,-1.50734 -1.26712,-1.35605 -1.58344,-3.3154 -1.58344,-8.89089 v -43.5532 c 0,-5.5764 0.31632,-7.68612 1.58344,-9.04218 1.0544,-0.90403 3.16412,-1.50733 5.59016,-1.50733 h 1.37255 v -3.46669 h -20.46547 l -15.40251,52.59537 -15.0706,-52.59537 H 336.9535 v 3.46669 h 1.89792 c 5.59199,0 6.64639,1.20568 6.64639,8.43979 v 41.44256 c 0,6.63081 -0.31632,9.34383 -1.2662,11.30318 -1.0544,1.95936 -3.28514,3.31541 -5.59107,3.31541 h -1.68796 v 3.61614 h 21.73075 v -3.61614 h -1.37072 c -2.74236,0 -4.95844,-1.05532 -6.23931,-3.16504 -1.25061,-2.10972 -1.56693,-4.06907 -1.56693,-11.45355 v -46.86768 l 18.55105,65.10332 h 3.28606"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <path
        inkscape:connector-curvature="0"
        id="path30"
        d="m 430.93183,277.48528 c -13.081,0 -21.62531,10.24786 -21.62531,26.37378 0,15.52262 8.54431,26.07214 21.29524,26.07214 12.86828,0 21.41351,-10.54952 21.41351,-26.2225 0,-15.52354 -8.54523,-26.22342 -21.08344,-26.22342 m -0.42176,3.46577 c 3.78301,0 7.05257,2.41137 8.84597,6.48044 1.68796,3.76742 2.42604,8.89181 2.42604,16.27629 0,15.22005 -3.69224,22.7549 -11.18124,22.7549 -7.47525,0 -11.07763,-7.53576 -11.07763,-22.90619 0,-7.23411 0.75275,-12.35758 2.44071,-16.125 1.6742,-3.91779 5.06479,-6.48044 8.54615,-6.48044"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <path
        inkscape:connector-curvature="0"
        id="path32"
        d="m 469.54217,256.08551 -14.98075,0.75367 v 3.3154 h 0.72341 c 4.43124,0 5.39579,1.50734 5.39579,8.59017 v 48.97831 c 0,6.32916 -0.21088,7.98778 -1.0544,10.69989 h 3.48135 c 2.62134,-4.37073 3.26956,-5.12348 4.32488,-5.12348 0.51253,0 1.02415,0.15037 1.77873,0.90404 5.18307,4.21944 7.39823,5.12347 12.14579,5.12347 11.37745,0 19.60636,-11.00153 19.60636,-26.67452 0,-14.76895 -7.61094,-24.71517 -18.98839,-24.71517 -5.9074,0 -10.21761,2.86339 -12.43277,7.98686 v -29.83864 m 10.94102,25.6192 c 6.76742,0 10.24787,7.23411 10.24787,21.39977 0,14.91932 -3.5868,22.60545 -10.44408,22.60545 -7.59536,0 -11.59016,-7.83742 -11.59016,-22.15344 0,-7.23411 0.95079,-12.2063 3.16595,-15.97463 1.9887,-3.61614 5.3802,-5.87715 8.62042,-5.87715"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <path
        inkscape:connector-curvature="0"
        id="path34"
        d="m 520.47984,278.23894 -15.38692,0.90404 v 3.3154 h 1.16076 c 4.41473,0 5.36552,1.50734 5.36552,8.4398 v 25.46883 c 0,6.93338 -0.94987,8.59108 -5.36552,8.59108 h -1.67329 v 3.46486 h 22.96853 v -3.46486 h -1.65862 c -4.44591,0 -5.40954,-1.50825 -5.40954,-8.59108 v -38.12807 m -5.05012,-22.00306 c -3.26956,0 -5.90649,2.56174 -5.90649,6.02842 0,3.16504 2.63693,5.87715 5.80196,5.87715 3.26956,0 5.80196,-2.56174 5.80196,-5.87715 0,-3.3154 -2.5324,-6.02842 -5.69743,-6.02842"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <path
        inkscape:connector-curvature="0"
        id="path36"
        d="m 547.16812,256.08551 -15.38693,0.75367 v 3.3154 h 1.14701 c 4.43032,0 5.37928,1.50734 5.37928,8.59017 v 47.62135 c 0,7.08374 -0.94896,8.59107 -5.37928,8.59107 h -1.67329 v 3.46486 h 22.98136 v -3.46486 h -1.68796 c -4.4459,0 -5.38019,-1.50825 -5.38019,-8.59107 v -60.28059"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <path
        inkscape:connector-curvature="0"
        id="path38"
        d="m 596.20878,303.40613 c -0.51345,-15.97464 -8.1088,-25.92085 -19.59169,-25.92085 -11.0923,0 -19.09475,10.85024 -19.09475,25.92085 0,16.125 7.89701,26.52415 20.13448,26.52415 7.91168,0 13.39731,-4.22035 17.93399,-13.56418 l -3.16412,-1.65771 c -3.69224,7.8365 -7.38448,11.00154 -13.08191,11.00154 -8.33344,0 -11.90465,-6.6299 -12.01193,-22.3038 h 28.87593 m -28.76957,-3.76834 c 0.0908,-11.30227 3.76651,-18.38509 9.68949,-18.38509 5.9074,0 9.38784,6.93246 9.28332,18.38509 h -18.97281"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <path
        inkscape:connector-curvature="0"
        id="path40"
        d="m 609.53458,288.33552 h 14.76804 v 15.07061 h -14.76804 z"
        style="fill: #ed008c; fill-opacity: 1; stroke-width: 0.91687071"
      />
      <g transform="matrix(0.91687074,0,0,0.91687074,129.24101,-70.315889)" id="g18" style="fill: #ed008c; fill-opacity: 1">
        <g id="g20" style="fill: #ed008c; fill-opacity: 1">
          <path
            inkscape:connector-curvature="0"
            d="m 545.988,377.995 c 0.972,0 1.921,0.249 2.847,0.748 0.926,0.499 1.647,1.213 2.163,2.141 0.518,0.928 0.776,1.896 0.776,2.904 0,0.998 -0.255,1.957 -0.765,2.877 -0.508,0.921 -1.223,1.636 -2.14,2.145 -0.919,0.509 -1.879,0.764 -2.882,0.764 -1.003,0 -1.963,-0.254 -2.882,-0.764 -0.918,-0.509 -1.634,-1.223 -2.144,-2.145 -0.512,-0.92 -0.768,-1.879 -0.768,-2.877 0,-1.008 0.259,-1.976 0.779,-2.904 0.519,-0.928 1.242,-1.643 2.166,-2.141 0.93,-0.499 1.879,-0.748 2.85,-0.748 z m 0,0.964 c -0.812,0 -1.604,0.208 -2.372,0.625 -0.769,0.417 -1.371,1.011 -1.806,1.785 -0.434,0.774 -0.65,1.581 -0.65,2.418 0,0.833 0.213,1.631 0.64,2.395 0.426,0.764 1.023,1.359 1.789,1.786 0.767,0.427 1.567,0.64 2.399,0.64 0.834,0 1.632,-0.213 2.399,-0.64 0.766,-0.426 1.361,-1.021 1.785,-1.786 0.424,-0.764 0.637,-1.562 0.637,-2.395 0,-0.837 -0.216,-1.644 -0.648,-2.418 -0.432,-0.773 -1.033,-1.368 -1.805,-1.785 -0.771,-0.417 -1.56,-0.625 -2.368,-0.625 z m -2.54,8.023 v -6.23 h 2.145 c 0.731,0 1.263,0.058 1.59,0.173 0.329,0.115 0.59,0.316 0.784,0.603 0.195,0.286 0.293,0.591 0.293,0.913 0,0.455 -0.164,0.852 -0.49,1.189 -0.324,0.337 -0.757,0.527 -1.296,0.568 0.221,0.092 0.397,0.202 0.53,0.33 0.252,0.245 0.559,0.657 0.922,1.235 l 0.761,1.22 h -1.233 L 546.899,386 c -0.435,-0.769 -0.784,-1.251 -1.047,-1.447 -0.184,-0.145 -0.453,-0.217 -0.806,-0.217 h -0.592 v 2.647 h -1.006 z m 1.007,-3.502 h 1.226 c 0.584,0 0.983,-0.087 1.197,-0.262 0.214,-0.175 0.319,-0.406 0.319,-0.694 0,-0.185 -0.051,-0.351 -0.154,-0.497 -0.103,-0.146 -0.244,-0.256 -0.428,-0.328 -0.182,-0.072 -0.52,-0.107 -1.013,-0.107 h -1.147 z"
            id="path23"
            style="fill: #ed008c; fill-opacity: 1"
          />
        </g>
      </g>
    </svg>
  </div>
</section>
