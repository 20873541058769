import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';

@Component({
  templateUrl: './auth-logout.component.html'
})
export class AuthLogoutComponent implements OnInit {
  constructor(
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.startLogout();
  }
}
