import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalApplicationSettings } from 'app/app.settings';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoadingIndicatorService } from '../loading-indicator.service';

@Injectable()
export class MaintenanceModeInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector, private readonly loadingIndicatorService: LoadingIndicatorService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(this.handleError));
  }

  private handleError = (error: Response): ObservableInput<any> => {
    if (error.status === 503) {
      this.settings.maintenanceMode = true;
      this.loadingIndicatorService.setPageInitialized();

      // Logging in maintenance mode does not work since the server returns a 503, should not be necessary either.
      this.router.navigate(['/maintenance'], { skipLocationChange: true, replaceUrl: true });
    }

    return throwError(error);
  }

  private get router(): Router {
    return this.injector.get(Router);
  }

  private get settings(): GlobalApplicationSettings {
    return this.injector.get<GlobalApplicationSettings>(GlobalApplicationSettings);
  }
}
