import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styles: []
})
export class AuthCallbackComponent implements OnInit {
  constructor(private readonly authService: AuthService) {}

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.completeAuthentication();
    }
  }
}
