import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { AccountService } from 'app/core/services/account.service';
import { SubscriptionIconType } from 'app/shared/magenta-subscription.model';
import { BasicCustomerInformationViewModel } from 'app/shared/open-api/data-contracts';
import { SessionStorage } from 'app/shared/storage/sessionstorage.service';
import { UserInformationModel } from 'app/shared/userinformation.models';
import { Observable, Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ProductNameService } from '../services/product-name.service';
import { ProductsService } from '../services/products.service';

declare let window: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  account: UserInformationModel | null;
  customerData: BasicCustomerInformationViewModel | null;
  iconSrc: string;
  iconType: SubscriptionIconType;
  magentaAccountUrl: string;
  productName$: Observable<string> = this.productNameService.productName();
  subscription: Subscription;

  constructor(
    private readonly authorizationService: AuthService,
    private readonly sessionStorage: SessionStorage,
    private readonly accountService: AccountService,
    private readonly productsService: ProductsService,
    private readonly productNameService: ProductNameService,
  ) {}

  get agentCustomerCode(): string {
    const customerCode = this.sessionStorage.get('CustomerCode');
    if (this.authorizationService.isLoggedIn() && customerCode) {
      return customerCode;
    }
    return '';
  }

  get loggedIn(): boolean {
    return this.authorizationService.isLoggedIn();
  }

  get loggedOut(): boolean {
    return !this.loggedIn;
  }

  get username(): string {
    return this.authorizationService.getUserName();
  }

  // On the smallest devices when the product name is long (3p customer) avatar should be hidden to prevent going to a newline
  canHideAvatar() {
    const screenWidth = window.innerWidth;
    return this.customerData?.customerInitials?.length !== 2 || this.iconType === SubscriptionIconType.Fixed3p && screenWidth < 396;
  }

  initialize() {
    window.TMobileDesignSystem.init();
  }

  logout() {
    this.authorizationService.startLogout();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.authorizationService.userLoaded
      .pipe(
        filter((user) => user != null),
        mergeMap(() => this.accountService.getBasicCustomerData()),
        mergeMap((data: BasicCustomerInformationViewModel | null) => {
          this.customerData = data;
          this.account = this.authorizationService.customerInformation;
          return this.productsService.getSubscriptionForCustomer();
        })
      ).subscribe((fixedSubscription) => {
        this.iconType = this.productNameService.subscriptionIconType(fixedSubscription);
        switch (this.iconType) {
          case SubscriptionIconType.PrePaid:
          case SubscriptionIconType.PostPaid:
            this.iconSrc = 'icon-smartphone';
            break;
          case SubscriptionIconType.Fixed1p:
            this.iconSrc = '/assets/images/shop/1p-subscription.svg';
            break;
          case SubscriptionIconType.Fixed2pTv:
            this.iconSrc = '/assets/images/shop/2p-tv-subscription.svg';
            break;
          case SubscriptionIconType.Fixed2pVoip:
            this.iconSrc = '/assets/images/shop/2p-voip-subscription.svg';
            break;
          case SubscriptionIconType.Fixed3p:
            this.iconSrc = '/assets/images/shop/3p-subscription.svg';
            break;
        }
      });

    this.magentaAccountUrl = (window as any).magentaAccountUrl;
  }
}
