import { Injectable } from '@angular/core';
import { SubscriptionIconType } from 'app/shared/magenta-subscription.model';
import { SubscriptionModel } from 'app/shared/open-api/data-contracts';
import { Observable, map } from 'rxjs';
import { ProductsService } from './products.service';

@Injectable()
export class ProductNameService {
  constructor(
    private readonly productsService: ProductsService,
  ) { }

  productName(): Observable<string> {
    return this.productsService.getSubscriptionForCustomer()
      .pipe(
        map((subscription) => {
          const iconType = this.subscriptionIconType(subscription);
          switch (iconType) {
            case SubscriptionIconType.Fixed1p:
              return 'Internet';
            case SubscriptionIconType.Fixed2pTv:
              return 'Internet + TV';
            case SubscriptionIconType.Fixed2pVoip:
              return 'Internet + Vast bellen';
            case SubscriptionIconType.Fixed3p:
              return 'Internet + TV + Vast bellen';
            default:
              return 'Odido';
          }
        })
      )
  }

  subscriptionIconType(subscription: SubscriptionModel): SubscriptionIconType {
    if (subscription.currentTv && subscription.currentPrimaryVoipProduct) {
      return SubscriptionIconType.Fixed3p;
    }

    if (!subscription.currentTv && !subscription.currentPrimaryVoipProduct) {
      return SubscriptionIconType.Fixed1p;
    }

    return subscription.currentTv ? SubscriptionIconType.Fixed2pTv : SubscriptionIconType.Fixed2pVoip;
  }
}
