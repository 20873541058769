import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ActivationStart,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { LoadingIndicatorService } from 'app/core/loading-indicator.service';
import { LoggingService } from 'app/core/logging.service';
import { Subscription } from 'rxjs';
import { environment } from './../environments/environment';
import { GlobalApplicationSettings } from './app.settings';
import { AuthService } from './auth/auth.service';
import { HeaderComponent } from './core/header/header.component';
import { TrackingService } from './shared/tracking.service';

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  designSystemsLoaded = false;

  @ViewChild(HeaderComponent) headerComponent: HeaderComponent;

  magentaSSOKeepAliveEndpoint: string | null = null;
  navigationHidden: boolean;
  routerSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly loggingService: LoggingService,
    private readonly injector: Injector,
    private readonly trackingService: TrackingService,
    private readonly authService: AuthService,
    readonly loadingIndicatorService: LoadingIndicatorService
  ) {
    if (environment.production) {
      this.appendGaTrackingCode();
    } else {
      this.loggingService.info(this, 'Not adding analytics because its not a production build');
    }
  }

  navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loadingIndicatorService.setPageLoading();
      this.setNavigationHidden(event.url);
    }

    if (event instanceof NavigationEnd) {
      this.loadingIndicatorService.setPageInitialized();

      // each component should say when it's ready, it's usually not on NavigationEnd
      this.trackingService.pageView();
    }

    if (event instanceof NavigationCancel) {
      this.loadingIndicatorService.setPageInitialized();
      this.loadingIndicatorService.setPageLoadComplete();
    }

    if (event instanceof NavigationError) {
      this.loadingIndicatorService.setPageInitialized();
      this.loadingIndicatorService.setPageLoadFailed();
    }

    if (event instanceof ActivationStart) {
      this.setHandleHttpExceptions(event);
    }
  }

  ngAfterViewInit(): void {
    this.waitForDesignSystems();
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });

    this.authService.keepAliveTriggered.subscribe({
      next: (val) => {
        this.magentaSSOKeepAliveEndpoint = null;

        setTimeout(() => {
          this.magentaSSOKeepAliveEndpoint = val;
        }, 50);
      }
    });
  }

  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${this.trackingService.measurementId}');
        `;
      document.head.appendChild(script);
    } catch (ex) {
      this.loggingService.error(this, 'Error appending google analytics', null, undefined, ex);
    }
  }

  private setHandleHttpExceptions(activation: ActivationStart) {
    const settings = this.injector.get<GlobalApplicationSettings>(GlobalApplicationSettings);

    let handleHttpExceptions = true;
    if (activation && activation.snapshot && activation.snapshot.data) {
      handleHttpExceptions = activation.snapshot.data['handleHttpExceptions'] !== false;
    }

    settings.handleHttpExceptions = handleHttpExceptions;
  }

  private setNavigationHidden(url: string): void {
    const isNavHiddenStored = localStorage.getItem('nav_off') === '1';
    const navHidden = url.indexOf('nav=off') > -1 || isNavHiddenStored;
    if ((window as any).navigationHidden === undefined || navHidden) {
      (window as any).navigationHidden = navHidden;
      if (isNavHiddenStored) {
        localStorage.removeItem('nav_off');
      }
    }

    this.navigationHidden = (window as any).navigationHidden;
  }

  private waitForDesignSystems() {
    if (typeof window.TMobileDesignSystem !== 'undefined') {
      // variable exists, do what you want
      this.headerComponent.initialize();
      this.designSystemsLoaded = true;
    } else {
      setTimeout(() => this.waitForDesignSystems(), 250);
    }
  }
}
