import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackingService } from 'app/shared/tracking.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TrackingEventInfoModel } from '../../shared/tracking.models';

@Injectable()
export class TrackingInterceptor implements HttpInterceptor {
  constructor(
    private readonly trackingService: TrackingService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const trackingCategory = req.headers.get('trackingCategory');
    const trackingLabel = req.headers.get('trackingLabel');

    if (!trackingCategory || !trackingLabel) {
      return next.handle(req);
    }

    const trackingInfo = {
      trackingCategory,
      trackingLabel
    } as TrackingEventInfoModel;

    const clonedReq = req.clone({ headers: this.trackingService.removeTrackingHeaders(req.headers)});

    return next.handle(clonedReq).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (!(evt.body?.success === false)) {
            this.trackingService.trackChangeSuccess(trackingInfo);
          }
        }
      }),
      catchError((ex) => {
        if (ex instanceof HttpErrorResponse) {
          this.trackingService.trackChangeError(trackingInfo);
        }
        return throwError(ex);
      })
    );
  }
}
