import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { Observable } from 'rxjs';
import { Endpoints } from '../Endpoints';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedRequest = req;
    if (req.url.endsWith(Endpoints.log) && !this.authService.isLoggedIn()) {
      modifiedRequest = req.clone({ url: req.url.replace(Endpoints.log, Endpoints.logExternal) });
    }

    return next.handle(modifiedRequest);
  }
}
