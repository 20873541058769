<ul class="list-group-inset w-100">
  <ng-container *ngFor="let subscription of subscriptions">
    <li [class.is-clickable]="!subscription.isFixedSubscription">
      <div
        class="flex align-items-center justify-content-between"
        (click)="openMagentaAccount(subscription)"
      >
        <div class="flex align-items-center">
          <span *ngIf="subscription.iconType === 0 || subscription.iconType === 1; else img" class="{{subscriptionIcon(subscription.iconType)}} icon-size-m mr-3 mt-1"></span>
          <ng-template #img>
            <img class="mr-3" [src]="subscriptionIcon(subscription.iconType)" style="max-width: 32px">
          </ng-template>
          <span class="text-weight-bold text-">{{ subscription.alias }}</span>
        </div>
        <a
          [class.stretched-link]="!subscription.isFixedSubscription"
          [class.text-muted]="!subscription.isFixedSubscription"
          [class.text-magenta]="subscription.isFixedSubscription"
          [class.no-pointer]="subscription.isFixedSubscription"
        >
          <span
            [class.icon-chevron-right]="!subscription.isFixedSubscription"
            [class.icon-checkmark-solid]="subscription.isFixedSubscription"
            class="text-black"
          ></span>
        </a>
        </div>
    </li>
  </ng-container>
</ul>
