<footer class="footer">
  <app-breadcrumb></app-breadcrumb>
  <div class="footer-sitemap">
    <div class="container">
      <div class="row">
        <div class="column-12">
          <div class="row flex-desktop-row-gap-5">
            <div class="column-12 column-desktop-2 mb-0">
              <div class="list-collapsable is-inversed">
                <input type="checkbox" id="list-1"/>
                <label for="list-1">Populaire telefoons</label>
                <div class="list-collapsable-content">
                  <ul>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/iphone"
                        >iPhone</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/iphone/iphone-14"
                        >iPhone 14</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/iphone/iphone-13"
                        >iPhone 13</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/samsung"
                        >Samsung</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/samsung/galaxy-s23-ultra"
                        >Samsung Galaxy S23 Ultra</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/samsung/galaxy-s22"
                        >Samsung Galaxy S22</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/shop/alle-telefoons"
                        >Alle telefoons</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="column-12 column-desktop-2 mb-0">
              <div class="list-collapsable is-inversed">
                <input type="checkbox" id="list-2"/>
                <label for="list-2">Abonnementen</label>
                <div class="list-collapsable-content">
                  <ul>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/mobiel-abonnement"
                        >Mobiel abonnement</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/mobiel-abonnement/sim-only"
                        >Sim Only</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/unlimited"
                        >Unlimited</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/samen-unlimited"
                        >Samen Unlimited</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/netwerk/5g"
                        >5G</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/service/producten/mobiel/4g-voor-thuis"
                        >4G voor Thuis</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/service/producten/mobiel/simkaart/dual-sim-esim"
                        >eSIM</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="column-12 column-desktop-2 mb-0">
              <div class="list-collapsable is-inversed">
                <input type="checkbox" id="list-3"/>
                <label for="list-3">Internet + TV</label>
                <div class="list-collapsable-content">
                  <ul>
                    <li class="mb-4">
                      <a
                      href="https://www.odido.nl/internet"
                      >Internet</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/internet/only"
                        >Internet Only
                      </a>
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/internet-en-tv"
                        >Internet + TV</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/internet/glasvezel"
                        >Glasvezel Internet</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/internet/glasvezelcheck"
                        >Glasvezelcheck</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/shop/alle-pakketten"
                        >Alle pakketten</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/tv"
                        >TV</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="column-12 column-desktop-2 mb-0">
              <div class="list-collapsable is-inversed">
                <input type="checkbox" id="list-4"/>
                <label for="list-4">Service + Contact</label>
                <div class="list-collapsable-content">
                  <ul>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/service/factuur"
                        >Vragen over je factuur</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/service/abonnement"
                        >Abonnement wijzigen</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/service/bestelling"
                        >Over je bestelling</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/service#contact"
                        >Neem contact op</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/shops"
                        >Shops</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://community.odido.nl/"
                        >Community</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="column-12 column-desktop-2 mb-0">
              <div class="list-collapsable is-inversed">
                <input type="checkbox" id="list-5"/>
                <label for="list-5">Snel naar</label>
                <div class="list-collapsable-content">
                  <ul>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/login"
                        >Inloggen</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/my/registreren"
                        >Registreren</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/my/inloggegevens-opvragen"
                        >Wachtwoord vergeten</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/my/contract-verlengen-of-wijzigen"
                        >Verlengen</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/uni/my/abonnement-overzicht"
                        >Odido App</a
                      >
                    </li>
                    <li class="mb-4">
                      <a
                        href="https://www.odido.nl/service"
                        >Service</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-assorted-links">
    <div class="container">
      <div class="row">
        <div class="column-12">
          <div class="row">
            <div class="column-12 column-desktop-large-4 mb-0 flex align-items-center py-6 py-desktop-large-0">
              <img
                src="/assets/images/odido-logo-white.svg?v=2023"
                alt="logo"
                class="pr-5"
                width="160"
              />
            </div>
            <div class="column-12 column-desktop-large-4 mb-0 flex-desktop-large justify-content-center pb-2 pb-desktop-large-0">
              <ul class="footer-assorted-link-list footer-social-links">
                <li>
                  <a href="https://www.x.com/odidonederland" rel="noopener noreferrer" target="_blank">
                    <img
                      src="/assets/images/x.svg?v=2023"
                      alt="x"
                      width="32"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/odidonederland" rel="noopener noreferrer" target="_blank">
                    <span class="icon-facebook-solid icon-size-m"></span>
                    <span class="sr-only">Facebook</span>
                  </a>
                </li>
                <li class="mr-2">
                  <a href="https://www.linkedin.com/company/odidonederland" rel="noopener noreferrer" target="_blank">
                    <span class="icon-linkedin-solid icon-size-m"></span>
                    <span class="sr-only">LinkedIn</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.community.odido.nl" rel="noopener noreferrer" target="_blank">
                    <span class="icon-chat-solid icon-size-m"></span>
                    <span class="sr-only">Forum</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="column-12 column-desktop-large-4 mb-0 ml-auto">
              <ul class="footer-assorted-link-list footer-appstore-links">
                <li class="ml-desktop-large-auto">
                  <a href="https://apps.apple.com/nl/app/id484504034">
                    <img src="/assets/images/ios-app-store.svg?v=2023" alt="app store" />
                  </a>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=nl.oberon.tmobile.my&hl=nl">
                    <img src="/assets/images/google-play-store.svg?v=2023" alt="play store" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-assorted-links">
    <div class="container">
      <div class="row">
        <div class="column-12">
          <ul class="footer-assorted-link-list footer-info-links">
            <li>
              <a
                href="https://www.odido.nl/over-ons"
                title="Over Odido"
                >Over Odido</a
              >
            </li>
            <li>
              <a
                href="https://www.odido.nl/newsroom"
                title="Odido nieuws"
                >Odido nieuws</a
              >
            </li>
            <li>
              <a
                href="https://werkenbij.odido.nl/vacatures"
                title="Werken bij Odido"
                >Werken bij Odido</a
              >
            </li>
            <li>
              <a
                href="https://www.odido.nl/blog"
                title="Blog"
                >Blog</a
              >
            </li>
            <li>
              <a
                href="https://www.odido.nl/tarieven"
                title="Tarieven"
                >Tarieven</a
              >
            </li>
            <li>
              <a
                href="https://www.odido.nl/voorwaarden"
                title="Voorwaarden"
                >Voorwaarden</a
              >
            </li>
            <li>
              <a
                href="https://www.odido.nl/privacy"
                title="Privacy"
                >Privacy</a
              >
            </li>
            <li>
              <a
                href="https://www.odido.nl/cookies"
                title="Cookies"
                >Cookies</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container">
      <div class="row">
        <div class="column-12">
          <p><span class="text-nowrap">© Odido.</span> Alle rechten voorbehouden</p>
        </div>
      </div>
    </div>
  </div>
</footer>
