import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestCache } from 'app/core/request-cache';
import { Observable, of } from 'rxjs';
import { finalize, shareReplay, tap } from 'rxjs/operators';

export enum HttpType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT'
}

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private readonly observableCache = new Map<string, Observable<HttpEvent<any>>>();

  constructor(private requestCache: RequestCache) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('/api')) {
      return next.handle(request);
    }

    if (request.method !== HttpType.GET) {
      this.requestCache.clearFor(request.url);
      return next.handle(request);
    }

    const response = this.requestCache.get(request);
    if (response) {
      return of(response);
    }

    let observable = this.observableCache.get(request.url);
    if (!observable) {
      observable = next.handle(request).pipe(
        tap({
          next: (event) => {
            if (event instanceof HttpResponse) {
              this.requestCache.set(request, event);
            }
          }
        }),
        finalize(() => {
          this.observableCache.delete(request.url);
        }),
        shareReplay(1)
      );
      this.observableCache.set(request.url, observable);
    }

    return observable;
  }
}
