import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from 'app/core/loading-indicator.service';

@Component({
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  constructor(private loadingIndicatorService: LoadingIndicatorService) {}

  ngOnInit(): void {
    this.loadingIndicatorService.setPageLoadComplete();
  }
}
